html {
  scroll-behavior: smooth;
  background-color: #f1f3c4;
}
body {
  background-color: #f1f3c4;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiSvgIcon-colorPrimary {
  color: #13743a !important;
}

.MuiButton-containedPrimary {
  background-color: #13743a !important;
}

.MuiInputBase-root {
  color: #f5f5f4 !important;
}
.MuiFilledInput-input {
  color: black !important;
}

.MuiButton-textPrimary {
  color: black !important;
}

.MuiInput-underline:after {
  border-bottom: rgba(99, 98, 98, 0.993) !important;
}

/* .MuiTablePagination-root {
  background-color: #13743a;
} */

.MuiSelect-root {
  color: #474838 !important;
}

/* .MuiButton-contained:hover {
  color: #13743a !important;
} */
.MuiFormLabel-root.Mui-focused {
  right: -230 !important;
}
